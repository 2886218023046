import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import GlobalServices from '../../components/feature/Services/GlobalServices';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
// import User from '../User/User';
import { componentsMenu, dashboardMenu, menuMaster, menuMasterAdmin, menuOperator } from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import Card, { CardBody } from '../../components/bootstrap/Card';

import Hand from '../../assets/img/hand.png';
import HandWebp from '../../assets/img/hand.webp';
// import Icon from '../../components/icon/Icon';
import Button from '../../components/bootstrap/Button';
import Tooltips from '../../components/bootstrap/Tooltips';
import useDeviceScreen from '../../hooks/useDeviceScreen';



const Aside = () => {
	const token = sessionStorage.getItem('access_token');
	const [dataToken, setDataToken] = useState('kosong');
	const [sideMenu, setSideMenu] = useState(null)

	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const deviceScreen = useDeviceScreen();
	const mobileDesign = deviceScreen?.width <= process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE;
	const touchButton = deviceScreen?.width > process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE;
	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const asideWidthWithSpace =
		(parseInt(process.env.REACT_APP_ASIDE_WIDTH_PX, 10) +
			parseInt(process.env.REACT_APP_SPACER_PX, 10)) *
		-1;

	const constraintsRef = useRef(null);
	const x = useMotionValue(
		process.env.REACT_APP_ASIDE_TOUCH_STATUS === 'true' ? 0 : asideWidthWithSpace,
	);
	const [touchStatus, setTouchStatus] = useState(!x.get());
	const left = useTransform(
		x,
		[-100, -90, -10, 0],
		[asideWidthWithSpace, asideWidthWithSpace, 0, 0],
	);

	// X value get
	useEffect(() => {
		if(token){
			let resToken = GlobalServices.DataToken(token)
			setDataToken(resToken)

			if (resToken.sub.data_karyawan) {
				GlobalServices.getAll(`administrasi/karyawan-akses/karyawan/${resToken.sub.data_karyawan.uuid}`, token)
					.then((response) => {
						console.log('reponse menu')
						console.log(response)
						if (resToken.sub.role === 'Admin') {
							let tmpMenu = {...menuMasterAdmin}

							let hakAkses = {
								dosen: false,
								surat_masuk: false,
								mahasiswa: false,
								kepegawaian: false,
								penomoran_surat: false,
							}

							response.results.data.forEach(dt => {
								if (dt.jenis.toLowerCase() === 'dosen') {
									hakAkses = {
										...hakAkses,
										dosen: true
									}
								} else if (dt.jenis.toLowerCase() === 'mahasiswa') {
									hakAkses = {
										...hakAkses,
										mahasiswa: true
									}
								} else if (dt.jenis.toLowerCase() === 'surat masuk') {
									hakAkses = {
										...hakAkses,
										surat_masuk: true
									}
								} else if (dt.jenis.toLowerCase() === 'kepegawaian') {
									hakAkses = {
										...hakAkses,
										kepegawaian: true
									}
								} else if (dt.jenis.toLowerCase() === 'penomoran surat') {
									hakAkses = {
										...hakAkses,
										penomoran_surat: true
									}
								} else {
									hakAkses = {
										...hakAkses,
										surat_masuk: true,
										dosen: true,
										mahasiswa: true,
										kepegawaian: true,
										penomoran_surat: true,
									}
								}
							})

							// cek available menu
							// let availableMenu = tmpMenu
							Object.keys(hakAkses).forEach(key => {
								console.log('Apa ini')
								console.log(key)
								if (hakAkses[key] === false) {
									if (key === 'mahasiswa') {
										delete tmpMenu.suratMahasiswa
									} else if (key === 'dosen') {
										delete tmpMenu.suratKeluar
									} else if (key === 'surat_masuk') {
										delete tmpMenu.suratMasuk
									} else if (key === 'kepegawaian') {
										delete tmpMenu.kepegawaian
										delete tmpMenu.master
									} else if (key === 'penomoran_surat') {
										delete tmpMenu.riwayatPenomoran
									}
								} else if (hakAkses[key] === true) {
									console.log(menuMasterAdmin);
									if (key === 'mahasiswa') {
										tmpMenu = {
											...tmpMenu,
											suratMahasiswa : menuMasterAdmin.suratMahasiswa
										}
									} else if (key === 'dosen') {
										tmpMenu = {
											...tmpMenu,
											suratKeluar : menuMasterAdmin.suratKeluar
										}
									} else if (key === 'surat_masuk') {
										tmpMenu = {
											...tmpMenu,
											suratMasuk : menuMasterAdmin.suratMasuk
										}
									} else if (key === 'kepegawaian') {
										tmpMenu = {
											...tmpMenu,
											master : menuMasterAdmin.master,
											kepegawaian : menuMasterAdmin.kepegawaian,
										}
									} else if (key === 'penomoran_surat') {
										tmpMenu = {
											...tmpMenu,
											riwayatPenomoran : menuMasterAdmin.riwayatPenomoran,
										}
									}
								}
							})
							console.log(tmpMenu);
							// set new available menu
							setSideMenu(tmpMenu)

						}
						console.log(response);
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				switch (resToken.sub.role) {
					case 'Admin':
						setSideMenu(menuMasterAdmin)
						break;
				
					case 'Super Operator':
						setSideMenu(menuOperator)
						break;
				
					case 'Super Admin':
						setSideMenu(menuMaster)
						break;
				
					default:
						break;
				}
				
			}
			
		}
		function updateX() {
			const X = x.get();
			setTouchStatus(!X);
		}

		const unsubscribeX = x.onChange(updateX);

		return () => {
			unsubscribeX();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Set Aside & Touch Button value
	useEffect(() => {
		if (!touchButton) {
			x.set(0);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [touchButton, deviceScreen.width]);

	//  for start minimize aside
	useEffect(() => {
		if (!asideStatus) {
			x.set(0);
			setTouchStatus(false);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [asideStatus]);

	const [doc, setDoc] = useState(false);

	const { t } = useTranslation(['translation', 'menu']);

	const setMenu = (role) => {
		switch (role) {
			case 'Admin':
				return (
					<Navigation menu={sideMenu} id='aside-menu-master-admin' />
				)
				// break;
			
			case 'Super Operator':
				return (
					<Navigation menu={sideMenu} id='aside-menu-master-operator' />
				)
				// break;
			case 'Super Admin':
				return (
					<Navigation menu={sideMenu} id='aside-menu-master' />
				)
				// break;
			default:
				return ''
				// break;
		}
	}

	return (
		<>
			<motion.aside
				style={touchButton ? { left } : { left: mobileDesign ? null : '0rem' }}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': touchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && touchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && touchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body'>
					<Navigation menu={dashboardMenu} id='aside-dashboard' />
					<NavigationLine />
						{/* {setMenu(dataToken.sub.role)} */}
						{sideMenu ? (
							<Navigation menu={sideMenu} />
						) : ''}
					{/* {
						dataToken && dataToken.sub.role === 'Admin'
						?
						<>
							<Navigation menu={menuMasterAdmin} id='aside-menu-master-admin' />
						</>
						:
						<>
							<Navigation menu={menuMaster} id='aside-menu-master' />
						</>
					} */}

					{doc && (
						<>
							<NavigationLine />
							<Navigation menu={componentsMenu} id='aside-menu-two' />
							<NavigationLine />
						</>
					)}

					{asideStatus && doc && (
						<Card className='m-3 '>
							<CardBody className='pt-0'>
								<img
									srcSet={HandWebp}
									src={Hand}
									alt='Hand'
									width={130}
									height={130}
								/>
								<p className='h4 text-dark'>{t('Everything is ready!')}</p>
								<Button
									color='info'
									isLight
									className='w-100'
									onClick={() => setDoc(false)}>
									{t('Demo Pages')}
								</Button>
							</CardBody>
						</Card>
					)}
				</div>
				<div className='aside-foot'>
					<nav aria-label='aside-bottom-menu'>
						<div className='navigation'>
							{/* <div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => setDoc(!doc)}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											icon={doc ? 'ToggleOn' : 'ToggleOff'}
											color={doc ? 'success' : null}
											className='navigation-icon'
										/>
										<span className='navigation-text'>
											{t('Documentation')}
										</span>
									</span>
								</span>
							</div> */}
						</div>
					</nav>
					{/* <User /> */}
				</div>
			</motion.aside>
			{asideStatus && touchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
