import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { Link, useLocation, useHistory } from 'react-router-dom';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import SelectData from './Services/SelectData';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import ModalEdit from './Instansi/ModalEditInstansi';

// Alert
import showNotification from '../extras/showNotification';
import GlobalServices from './Services/GlobalServices';
import ModalData from './SuratMasuk/ModalData';
import ModalDetail from './SuratMasuk/ModalDetail';


const FileDownload = require("js-file-download");

const SuratKeluarCetakLaporan = ({ setReset }) => {
	const history = useHistory();
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansiRole = localStorage.getItem('uuid_instansi');
	const token = sessionStorage.getItem('access_token');
	
	const location = useLocation()
  	const setDataProp = location.state?.dataProp
	// const history = useHistory();

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});

	const [loading, setLoading] = useState(false);

	// Fitur Filter Date
	const paramState = {
		startDate: '',
		endDate: '',
	};
	const [paramValue, setParam] = useState(paramState);
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setParam({ ...paramValue, [name]: value });
	};
	// End Fitur Filter Date
	
	const resetFilter = () => {
		setParam(paramState);
	};

	const cetakLaporan = () => {
		if(paramValue.startDate && paramValue.endDate){
			setLoading(true);
			GlobalServices.cetakLaporan(`administrasi/pengajuan-surat/export?first_date=${paramValue.startDate}&last_date=${paramValue.endDate}`, token)
				.then((response) => {
					setLoading(false);
					// Cara 1
					// const downloadUrl = window.URL.createObjectURL(new Blob([response]));
					// const link = document.createElement('a');
					// link.href = downloadUrl;
					// link.setAttribute('download', 'Laporan Surat Masuk.xlsx');
					// document.body.appendChild(link);
					// link.click();
					// link.remove();

					// Cara 2 Singkat
					FileDownload(response, `Laporan Surat Dosen Tanggal ${paramValue.startDate} - ${paramValue.endDate}.xlsx`);
					setTimeout(() => {
						resetFilter()
					}, 1000);
				})
				.catch((e) => {
					setLoading(false);
					console.log(e);
				});
		}
		else if(paramValue.startDate === ''){
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Cetak Laporan</span>
				</span>,
				'Pilih Tanggal Awal Terlebih Dahulu!',
			);
		}
		else if(paramValue.endDate === ''){
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Cetak Laporan</span>
				</span>,
				'Pilih Tanggal Akhir Terlebih Dahulu!',
			);
		}
	};
	
	return (
		<>
			{
				loading
				?
				<>
				<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
					<br/><br/><br/><br/>
					<div className='row g-3'>
					{Object.keys(COLORS).map((color) => (
						<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
							<Spinner color={COLORS[color]} isSmall isGrow />
						</div>
					))}
					</div>
					<br/><br/><br/><br/>
				</div>
				</>
			:
				<>
					<div className='row'>
						<div className='col-6' align='center'>
							<p align='left'><b>Dari Tanggal</b></p>
							<Input
								type='date'
								name='startDate'
								value={paramValue.startDate}
								onChange={handleInputChange}
								isRequired
							/>
						</div>
						<div className='col-6' align='right'>
							<p align='left'><b>Sampai Tanggal</b></p>
							<Input
								type='date'
								name='endDate'
								value={paramValue.endDate}
								onChange={handleInputChange}
								isRequired
							/>
						</div>
						<div className='col-12'>&nbsp;</div>
						<div className='col-12' align='right'>
							<Button style={{width:'150px'}} color='success' onClick={cetakLaporan}>
								Cetak
							</Button>
						</div>
					</div>
				</>
			}
						
		</>
	);
};

export default SuratKeluarCetakLaporan;
