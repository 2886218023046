import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe'
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import GlobalServices from '../Services/GlobalServices';
import COLORS from '../Services/Spinner';
import Spinner from '../../bootstrap/Spinner';
import SelectData from '../Services/SelectData';

const ModalDetail = ({ id, isOpen, setIsOpen, detailSurat, title, syaratSurat, 
	statusDisposisi, statusRefresh }) => {
	const jabatanStrukturalUser = localStorage.getItem('uuid_jabatan_struktural');
	const roleUser = localStorage.getItem('role_user');
	const uuidPengguna = localStorage.getItem('uuid_pengguna');
	const uuidInstansi = localStorage.getItem('uuid_instansi');
	const token = sessionStorage.getItem('access_token');

	const initSurat = {
		data_pengajuan_surat_disposisi: [],
		data_surat_instansi: {},
		kode_pengajuan: '',
		nama: '',
		nim: '',
		nip_dosen_pengampu: '',
		prodi: '',
		status: '',
		syarat_surat: '',
		tanggal_pengajuan: '',
		uuid: '',
		uuid_surat_instansi: '',
	};
	
	// Fitur Modal
	const [state, setState] = useState(false);
	const [stateVerif, setStateVerif] = useState(false);
	const [statePreview, setStatePreview] = useState(false);
	const [jenisVerif, setJenisVerif] = useState('');
	const [ listTTD, setListTTD ] = useState([]);
	const [ selectedTTD, setSelectedTTD ] = useState(null);

	const [loading, setLoading] = useState(false);
	const [loadingTTD, setLoadingTTD] = useState(false);
	const [loadingPreview, setLoadingPreview] = useState(false);
	const [linkPreviewCetak, setlinkPreviewCetak] = useState('');
	const [statusCQ, setstatusCQ] = useState(false);
	const [statusTujuanSurat, setstatusTujuanSurat] = useState(false);
	const [statusSalamPenutup, setstatusSalamPenutup] = useState(false);
	const [ surat, setSurat ] = useState(initSurat);
	const [statusSetujuAdmin, setStatusSetujuAdmin] = useState(false);

	const [nomorSuratNext, setNomorSuratNext] = useState([]);
	const [selectedNomorSuratNext, setSelectedNomorSuratNext] = useState([]);
	const [statusOpenButton, setStatusOpenButton] = useState(false);
	const [statusInput, setStatusInput] = useState(false);
	
	const [listStatusPenggantiTTD, setListStatusPenggantiTTD] = useState(SelectData.statusPenggantiTTD);
	const [selectedStatusPengganti, setSelectedStatusPengganti] = useState(null);
	
	// Set Detail Data Pengajuan
	const [dataDetailParagrafWithValue, setdataDetailParagrafWithValue] = useState([]);
	const [dataDetailKeyValueParagraf, setdataDetailKeyValueParagraf] = useState([]);
	const [dataDetailVariabel, setdataDetailVariabel] = useState([]);

	useEffect(
		() => {
			if (detailSurat) {
				console.log('detailSurat')
				console.log(detailSurat)
				setSurat(detailSurat);
				setDataPengajuanSuratDisposisi(detailSurat.data_pengajuan_surat_disposisi)

				let statusDisAdmin = false
				let newArray = detailSurat.data_pengajuan_surat_disposisi;	
				newArray = newArray.filter(day => day.uuid_jabatan_struktural === jabatanStrukturalUser);
				newArray = newArray.filter(day => day.status === 'Proses');
				
				if(newArray.length!==0){
					if(newArray[0].status === 'Selesai'){
						statusDisAdmin = false
					}
					else{
						statusDisAdmin = true
					}
				}

				setStatusSetujuAdmin(statusDisAdmin)

				GlobalServices.getListBy('configurasi-tanda-tangan', 'instansi', uuidInstansi, token).then((hasil) => {

					let frmSelect = [];
					hasil.data.results.forEach(dt => {
						let dataConfig = JSON.parse(dt.config_ttd)

						let pengganti = ''
						if(dataConfig.isi[1].value !== ''){
							let jb_pengganti = ''
							if(dataConfig.isi[3].value !== ''){
								jb_pengganti = ` - ${dataConfig.isi[3].value}`
							}
							pengganti = `(${dataConfig.isi[1].value} ${dataConfig.isi[6].value}${jb_pengganti})`
						}
						frmSelect.push({ value: dt.config_ttd, label: `${dataConfig.isi[4].value} - ${dataConfig.isi[2].value} ${pengganti}` });
					});

					setListTTD(frmSelect)
				});

				if (typeof detailSurat.kode_pengajuan !== 'undefined' && detailSurat.kode_pengajuan !== '') {
				GlobalServices.get(detailSurat.kode_pengajuan, 'administrasi/pengajuan-surat-mahasiswa', token).then((hasil) => {

					// Set Tujuan Surat Aktif Nonaktif
					let dataKomponen = [];
					hasil.data.results.data_pengajuan_surat_detail.forEach((dt, key) => {
						let isiKomponen = JSON.parse(dt.isi)
						dataKomponen.push({ komponen: isiKomponen.komponen });
					});

					let newArrayKomponen = dataKomponen;	
					newArrayKomponen = newArrayKomponen.filter(day => day.komponen === 'tujuan_surat');

					if(newArrayKomponen.length !== 0){
						setstatusTujuanSurat(true)
					}

					
					console.log('show data komponennya')
					console.log(dataKomponen)
					
					// End Set Tujuan Surat Aktif Nonaktif

					// Set Salam Penutup Aktif Nonaktif
					let dataKomponenTTD = [];
					hasil.data.results.data_pengajuan_surat_detail.forEach((dt, key) => {
						let isiKomponenTTD = JSON.parse(dt.isi)
						dataKomponenTTD.push({ komponen: isiKomponenTTD });
					});
					
					console.log(dataKomponenTTD)
					
					let newArrayKomponenTTD = dataKomponenTTD;	
					newArrayKomponenTTD = newArrayKomponenTTD.filter(day => day.komponen.komponen === 'tanda_tangan');

					let newArraySalamPenutup = newArrayKomponenTTD[0].komponen.isi;	
					newArraySalamPenutup = newArraySalamPenutup.filter(day => day.key === 'salam_penutup');

					if(newArraySalamPenutup[0].value !== ''){
						setstatusSalamPenutup(true)
					}
					else{
						setstatusSalamPenutup(false)
					}
					// End Set Salam Penutup Aktif Nonaktif

					// Set Detail Data Pengajuan
					let getKomponenPengajuanParagraf = [];
					hasil.data.results.data_pengajuan_surat_detail.forEach((dt, key) => {
						let isiKomponenPengajuan = JSON.parse(dt.isi)
						getKomponenPengajuanParagraf.push({ data: isiKomponenPengajuan });
					});
					
					console.log('getKomponenPengajuanParagraf')
					console.log(getKomponenPengajuanParagraf)
					
						// Paragraf With Value
						let newArrayKomponenParagrafWithValue = getKomponenPengajuanParagraf;	
						newArrayKomponenParagrafWithValue = newArrayKomponenParagrafWithValue.filter(day => day.data.komponen === 'paragraf_with_value');	
						console.log('newArrayKomponenParagrafWithValue')
						console.log(newArrayKomponenParagrafWithValue)

						
						let setKomponenPengajuanParagraf = [];
						newArrayKomponenParagrafWithValue.forEach((dt, key) => {
							setKomponenPengajuanParagraf.push({ ...setKomponenPengajuanParagraf.data, data: dt.data.isi.value });
						});
						// End Paragraf With Value

						// Key Value Paragraf
						let newArrayKomponenKeyValueParagraf = getKomponenPengajuanParagraf;	
						newArrayKomponenKeyValueParagraf = newArrayKomponenKeyValueParagraf.filter(day => day.data.komponen === 'key_value_paragraf');	
						console.log('newArrayKomponenKeyValueParagraf')
						console.log(newArrayKomponenKeyValueParagraf)

						let setKomponenKeyValueParagraf = [];
						newArrayKomponenKeyValueParagraf.forEach((dt, key) => {
							dt.data.isi.forEach((dt2, key2) => {
								setKomponenKeyValueParagraf.push({ ...setKomponenKeyValueParagraf.data, data: dt2 });
							});
						});
						// End Key Value Paragraf

						// Variabel
						let newArrayKomponenVariabel = getKomponenPengajuanParagraf;	
						newArrayKomponenVariabel = newArrayKomponenVariabel.filter(day => day.data.komponen === "variable");	
						console.log('newArrayKomponenVariabel')
						console.log(newArrayKomponenVariabel)

						let setKomponenVariabel = [];
						newArrayKomponenVariabel.forEach((dt, key) => {
							// console.log('Detail Variabel')
							// console.log(dt)
							// console.log(dt.data.isi)
							setKomponenVariabel.push({ ...setKomponenVariabel.data, data: dt.data.isi });
						});
						// End Variabel

					console.log('setKomponenPengajuanParagraf')
					console.log(setKomponenPengajuanParagraf)
					console.log(setKomponenKeyValueParagraf)
					console.log(setKomponenVariabel)
					setdataDetailParagrafWithValue(setKomponenPengajuanParagraf)
					setdataDetailKeyValueParagraf(setKomponenKeyValueParagraf)
					setdataDetailVariabel(setKomponenVariabel)
					// End Set Detail Data Pengajuan
				});
				}

			}
		},
		[detailSurat] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const tombolVerif = (stat) => {
		switch (stat) {
			case 'Verifikasi':
				return (
					<div align='right'>
						<Button
							icon='Check'
							color='success'
							style={{width:'130px'}}
							// isLight
							onClick={() => {
								setStateVerif(true);
								setIsOpen(false)
							}}

						>
							Verifikasi
						</Button>
					</div>
				)
				// break;
			case 'Proses':
				return (
					<>
					<Button
						icon='Close'
						color='danger'
						// isLight
						onClick={() => {
							getDetailDisposisi(detailSurat.uuid)
							setState(true);
							setIsOpen(false);
							setJenisVerif('Tolak');
						}}
						style={{width:'100px'}}
					>
						Tolak
					</Button>{'  '}
					<Button
						icon='Check'
						color='success'
						// isLight
						onClick={() => {
							getDetailDisposisi(detailSurat.uuid)
							setState(true);
							setIsOpen(false);
							setJenisVerif('Selesai')
						}}
						style={{width:'100px'}}
					>
						Setujui
					</Button>
					</>
				)
				// break;
			default:
				return ''
				// break;
		}
	}

	// Fitur Persetujuan Disposisi Selanjutnya
	const [dataDetailDisposisi, setDataDetailDisposisi] = useState([]);
	const [getDisposisi, setGetDisposisi] = useState([]);

		// set data pengajuan surat by jabatan struktural user
		const [dataPengajuanSuratDisposisi, setDataPengajuanSuratDisposisi] = useState(detailSurat.data_pengajuan_surat_disposisi);

		// set data select disposisi lanjutan
		const [listDisposisiLanjutan, setListDisposisiLanjutan] = useState([]);
		const [selectedDisposisi, setSelectedDisposisi] = useState(null);
	
	const getDetailDisposisi = (uuid) => {
		setLoading(true)
		let newArray = dataPengajuanSuratDisposisi;	

		if(roleUser === 'Super Operator'){
			newArray = newArray.filter(day => day.status === 'Proses');
			setDataPengajuanSuratDisposisi(newArray[0])
		}
		else{
			// Filter By Jabatan Struktural User
			newArray = newArray.filter(day => day.uuid_jabatan_struktural === jabatanStrukturalUser);
			setDataPengajuanSuratDisposisi(newArray[0])
			// Filter By Status Proses
			newArray = newArray.filter(day => day.status === 'Proses');
			setDataPengajuanSuratDisposisi(newArray[0])
		}

		GlobalServices.getAll(`administrasi/pengajuan-surat-mahasiswa/list-disposisi/${uuid}`, token)
			.then((response) => {
				let frmSelect = [];
				if(response.results){
					setLoading(false)
					response.results.forEach(dt => {
						frmSelect.push({ value: dt.uuid_jabatan_struktural, label: dt.data_jabatan_struktural.nama });
					});
				}
				setListDisposisiLanjutan(frmSelect)
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	}

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "disposisi") {
				let newArray = listDisposisiLanjutan;	
				newArray = newArray.filter(day => day !== e);
				setListDisposisiLanjutan(newArray)
			}
			else if (jenis === "ttd") {
				let newArray = listTTD;	
				newArray = newArray.filter(day => day !== e);
				setListTTD(newArray)
			}
		}
	};

	const handleSelectChange = (event, param) => {
		if (event) {
			switch (param) {
				case 'disposisi':
					setSelectedDisposisi(event);
					break;
				case 'ttd':
					setNomorSuratNext([])
					setSelectedNomorSuratNext(null)
					setLoadingTTD(true)
					setStatusInput(false)
					setStatusOpenButton(false)
					setdataValues({...dataValues, nomor_surat: ''})

					// Set Selected TTD
					if(statusSalamPenutup){
						setSelectedTTD(event)
					}
					else{
						let setUlang = event.value.replace(`"salam_penutup","value":"Wassalam"`, `"salam_penutup","value":""`);

						setSelectedTTD({...event, value: setUlang});
					}
					// End Set Selected TTD

					let dataTTD = JSON.parse(event.value)
					let jabatanStrukturalTTD = ''
					if(dataTTD.isi[1].value!==''){
						jabatanStrukturalTTD = dataTTD.isi[3].value
					}
					else{
						jabatanStrukturalTTD = dataTTD.isi[2].value
					}
					let data={
						uuid_surat: detailSurat.uuid_surat_instansi,
						uuid_instansi: uuidInstansi,
						penanda_tangan: jabatanStrukturalTTD,
					}
					
					GlobalServices.create(data, 'penomoran', token)
						.then((response) => {
							// console.log('Hasil Create nomor Selanjutnya')
							// console.log(response.data.results)
							if (response.data.code === 200) {
								let frmSelect = [];
								response.data.results.forEach(dt => {
									frmSelect.push({ value: dt, label: dt });
								});
								setNomorSuratNext(frmSelect)
								setLoadingTTD(false)
							}
							else{
								setLoadingTTD(false)
							}
						})
						.catch((e) => {
							setLoadingTTD(false)
							console.log(e);
						});
					break;
				case 'nomor_surat_next':
					setSelectedNomorSuratNext(event);
					setStatusOpenButton(true);
					setStatusInput(false)
					setdataValues({...dataValues, nomor_surat: event.value})

					break;
				case 'jabatan_pengganti_tujuan':
					setSelectedStatusPengganti(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'disposisi':
					setSelectedDisposisi(null);
					break;
				case 'ttd':
					setSelectedTTD(null);
					break;
				case 'nomor_surat_next':
					setSelectedNomorSuratNext(null);
					setStatusOpenButton(false);
					setStatusInput(false)
					setdataValues({...dataValues, nomor_surat: ''})

					break;
				case 'jabatan_pengganti_tujuan':
					setSelectedStatusPengganti(null);
					break;
				default:
					// no default
					break;
			}
		}
	};
	// End Fitur Persetujuan Disposisi Selanjutnya

	
	const formData = {
		uuid: detailSurat.uuid,
		status: '',
		catatan: '',
		tujuan: '',
		cq: '',
		tempat: '',
		nomor_surat: '',
	};
	
	const [dataValues, setdataValues] = useState(formData);
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setdataValues({ ...dataValues, [name]: value });
	};

	const handleSubmit = () => {
		if(jenisVerif === 'Selesai')
		{
			setLoading(true)
			if(listDisposisiLanjutan.length!==0){
				if (selectedDisposisi){
					let data = {
						uuid: dataPengajuanSuratDisposisi.uuid,
						uuid_pengajuan_surat: dataPengajuanSuratDisposisi.uuid_pengajuan_surat,
						uuid_jabatan_struktural: dataPengajuanSuratDisposisi.uuid_jabatan_struktural,
						status: 'Selesai',
						uuid_pengguna: uuidPengguna,
						memo:  dataPengajuanSuratDisposisi.memo,
						uuid_jabatan_struktural_tujuan: selectedDisposisi.value,
					};
	
					GlobalServices.create(data, 'administrasi/pengajuan-surat-mahasiswa/disposisi/create-with-tujuan', token)
						.then((response) => {
							if (response.data.code === 201) {
								setLoading(false)
								showNotification(
									<span className='d-flex align-items-center'>
										<Icon icon='Info' size='lg' className='me-1' />
										<span>Berhasil Setujui Pengajuan</span>
									</span>,
									'Berhasil Setujui Pengajuan Surat',
									);
									setSelectedDisposisi(null)
									setState(false);
									setIsOpen(false);
									statusRefresh();
							} else {
								setLoading(false)
								showNotification(
									<span className='d-flex align-items-center'>
										<Icon icon='Warning' size='lg' className='me-1' />
										<span>Gagal Setujui Pengajuan</span>
									</span>,
									'Gagal Setujui Pengajuan Surat',
								);
							}
						})
						.catch((e) => {
							setLoading(false)
							console.log(e);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Setujui Pengajuan</span>
								</span>,
								'Gagal Setujui Pengajuan Surat',
							);
						});
	
				} else {
					setLoading(false)
					// setIsOpen(false);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Warning' size='lg' className='me-1' />
							<span>Gagal Setujui Pengajuan</span>
						</span>,
						'Data Tidak Lengkap!',
					);
				}
			}
			else{
				let data = {
					uuid: dataPengajuanSuratDisposisi.uuid,
					uuid_pengajuan_surat: dataPengajuanSuratDisposisi.uuid_pengajuan_surat,
					uuid_jabatan_struktural: dataPengajuanSuratDisposisi.uuid_jabatan_struktural,
					status: 'Selesai',
					uuid_pengguna: uuidPengguna,
					memo:  dataPengajuanSuratDisposisi.memo,
					uuid_jabatan_struktural_tujuan: '',
				};

				GlobalServices.create(data, 'administrasi/pengajuan-surat-mahasiswa/disposisi/create-with-tujuan', token)
					.then((response) => {
						if (response.data.code === 201) {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Setujui Pengajuan</span>
								</span>,
								'Berhasil Setujui Pengajuan Surat',
								);
								setSelectedDisposisi(null)
								setState(false);
								setIsOpen(false);
								statusRefresh();
						} else {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Setujui Pengajuan</span>
								</span>,
								'Gagal Setujui Pengajuan Surat',
							);
						}
					})
					.catch((e) => {
						setLoading(false)
						console.log(e);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Setujui Pengajuan</span>
							</span>,
							'Gagal Setujui Pengajuan Surat',
						);
					});
			}
		}
		else if(jenisVerif === 'Tolak'){
			setLoading(true)
			if (dataValues.catatan !== '') {
				let data = {
					uuid: dataPengajuanSuratDisposisi.uuid,
					uuid_pengajuan_surat: dataPengajuanSuratDisposisi.uuid_pengajuan_surat,
					uuid_jabatan_struktural: dataPengajuanSuratDisposisi.uuid_jabatan_struktural,
					status: 'Tolak',
					uuid_pengguna: uuidPengguna,
					memo:  dataValues.catatan,
					uuid_jabatan_struktural_tujuan: '',
				};

				GlobalServices.create(data, 'administrasi/pengajuan-surat-mahasiswa/disposisi/create-with-tujuan', token)
					.then((response) => {
						if (response.data.code === 201) {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Tolak Pengajuan</span>
								</span>,
								'Berhasil Tolak Pengajuan Surat',
								);
								setState(false);
								setIsOpen(false);
								statusRefresh();
						} else {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Tolak Pengajuan</span>
								</span>,
								'Gagal Tolak Pengajuan Surat',
							);
						}
					})
					.catch((e) => {
						setLoading(false)
						console.log(e);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Tolak Pengajuan</span>
							</span>,
							'Gagal Tolak Pengajuan Surat',
						);
					});
			} else {
				setLoading(false)
				// setIsOpen(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Tolak Pengajuan</span>
					</span>,
					'Data Tidak Lengkap!',
				);
			}
		}
	};

	const handleSubmitVerifSurat = () => {
		if(statusDisposisi === 'Verifikasi'){
			let cekCQ = false;
			let cekTujuan = false;
			let cekNomorSurat = false;
			let setNomorSuratNow = '';
			let nomorTunggal = '';

			if(statusCQ){
				if(dataValues.cq !== ''){
					cekCQ = true;
				}
			}
			else{
				cekCQ = true;
			}

			if(statusTujuanSurat){
				if(dataValues.tujuan !== '' && dataValues.tempat !== ''){
					cekTujuan = true;
				}
			}
			else{
				cekTujuan = true;
			}

			if(statusInput && selectedTTD){
				
				let nomorNext = selectedNomorSuratNext.value;
				let splitFormatNomor = nomorNext.split('/');
				let splitNomorSugest = splitFormatNomor[0].split('.');
				let setNomorSugest = splitNomorSugest[1];

				// console.log('setNomorSugest')
				// console.log(splitNomorSugest)
				// console.log(setNomorSugest)
				// console.log(dataValues.nomor_surat)

				if(dataValues.nomor_surat >= setNomorSugest){
					setNomorSuratNow = nomorNext.replace(`B.${setNomorSugest}/F`, `B.${dataValues.nomor_surat}/F`);
					cekNomorSurat = true
					nomorTunggal = dataValues.nomor_surat
				}
				else{
					showNotification(
						<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Verifikasi</span>
					</span>,
					'Nomor Surat Tidak Boleh Kurang Dari Yang Telah Dianjurkan',
					);
				}
			}
			else if(!statusInput && selectedTTD){
				if(selectedNomorSuratNext){
					let nomorNext2 = selectedNomorSuratNext.value;
					let splitFormatNomor2 = nomorNext2.split('/');
					let splitNomorSugest2 = splitFormatNomor2[0].split('.');
					let setNomorSugest2 = splitNomorSugest2[1];

					cekNomorSurat = true
					setNomorSuratNow = selectedNomorSuratNext.value;
					nomorTunggal = setNomorSugest2
				}
			}

			if (
					selectedTTD &&
					cekNomorSurat &&
					cekCQ &&
					cekTujuan
				) 
			{
				setLoading(true)
				let statusPengganti = ''
				if(selectedStatusPengganti){
					statusPengganti = selectedStatusPengganti.value
				}

				let isiTujuanSurat = [
					{key: '', value: 'Kepada Yth. :'},
					{key: statusPengganti, value: dataValues.tujuan},
					{key: '', value: dataValues.tempat},
				];
				if(statusCQ){
					isiTujuanSurat = [
						{key: '', value: 'Kepada Yth. :'},
						{key: statusPengganti, value: dataValues.tujuan},
						{key: 'c.q', value: dataValues.cq},
						{key: '', value: dataValues.tempat},
					]
				}
				
				let dataTujuanSurat = {
					komponen: 'tujuan_surat',
					isi: isiTujuanSurat,
				}

				let data = {
					uuid: detailSurat.uuid,
					status: 'Menunggu TTD',
					catatan: dataValues.catatan,
					tanda_tangan: selectedTTD.value,
					nomor_surat: setNomorSuratNow,
				};

				if(statusTujuanSurat){
					data = {
						uuid: detailSurat.uuid,
						status: 'Menunggu TTD',
						catatan: dataValues.catatan,
						tanda_tangan: selectedTTD.value,
						tujuan: JSON.stringify(dataTujuanSurat),
						nomor_surat: setNomorSuratNow,
					};
				}
				
				// console.log('Hasil SImpan TTD')
				// console.log(data)
				// console.log('setNomorSuratNow')
				// console.log(setNomorSuratNow)
				// console.log('cekNomorSurat')
				// console.log(cekNomorSurat)
				GlobalServices.create(data, 'administrasi/pengajuan-surat-mahasiswa/verifikasi', token)
					.then((response) => {
						// console.log('Response Simpan')
						// console.log(response)
						if (response.data.code === 201) {
							
							// Create Nomor Surat
							let dataNomor = {
								uuid_instansi: uuidInstansi,
								nomor: nomorTunggal,
							}

							GlobalServices.create(dataNomor, 'penomoran/create', token)
							.then((hasil) => {
								// console.log('Hasil Create Penomoran')
								// console.log(hasil)
								if (hasil.data.code === 200) {
									setLoading(false)
									showNotification(
										<span className='d-flex align-items-center'>
										<Icon icon='Info' size='lg' className='me-1' />
										<span>Berhasil Verifikasi</span>
									</span>,
									'Berhasil Verifikasi Surat Mahasiswa',
									);
									tutupVerifikasi();
									setIsOpen(false);
									setstatusTujuanSurat(false)
									statusRefresh();
								} else {
									setLoading(false)
									showNotification(
										<span className='d-flex align-items-center'>
											<Icon icon='Warning' size='lg' className='me-1' />
											<span>Gagal Verifikasi</span>
										</span>,
										'Gagal Verifikasi Surat Mahasiswa',
									);
								}
							})
							.catch((e) => {
								setLoading(false)
								console.log(e);
							});

							// End Create Nomor Surat
						} else {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Verifikasi</span>
								</span>,
								'Gagal Verifikasi Surat Mahasiswa',
							);
						}
					})
					.catch((e) => {
						setLoading(false)
						console.log(e);
						// showNotification(
						// 	<span className='d-flex align-items-center'>
						// 		<Icon icon='Warning' size='lg' className='me-1' />
						// 		<span>Gagal Verifikasi</span>
						// 	</span>,
						// 	'Gagal Verifikasi Surat Mahasiswa',
						// );
					});
			} else {
				// setIsOpen(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Verifikasi</span>
					</span>,
					'Data Tidak Lengkap!',
				);
			}
		}
	};

	const statusColor = (stat) => {
		switch (stat) {
			case 'Proses':
				return ('tl-dot b-primary')
				// break;
			case 'Selesai':
				return ('tl-dot b-success')
				// break;
			case 'Tolak':
				return ('tl-dot b-danger')
				// break;
			default:
				return 'tl-dot b-primary'
				// break;
		}
	}

	
	const tutupVerifikasi = () => {
		setState(false);
		setStateVerif(false);
		setIsOpen(true);
		setDataPengajuanSuratDisposisi(detailSurat.data_pengajuan_surat_disposisi);
		setJenisVerif('')
		setSelectedDisposisi(null)
		setdataValues(formData);
		setSelectedStatusPengganti(null);
		setSelectedTTD(null);
		setstatusCQ(false);
	};

	// Sorting Status Disposisi
	detailSurat.data_pengajuan_surat_disposisi.sort(function (x, y) {
		let a = new Date(x.created_at);
		let	b = new Date(y.created_at);
		return a - b;
	});
	// End Sorting Status Disposisi

	const formatNomorSurat = (data, jenis) => {
		switch (jenis) {
			case 'awal':
				let nomorNextAwal = data;
				let splitNomorAwal = nomorNextAwal.split('/')
				let splitNomorEditAwal = splitNomorAwal[0].split('.');
				let setNomorEditAwal = splitNomorEditAwal[0];
				return `${setNomorEditAwal}.`;
				// break;
			case 'akhir':
				let nomorNextAkhir = data;
				let setNomorEditAkhir = nomorNextAkhir.split('F.')
				return `/F.${setNomorEditAkhir[1]}`;
				// break;
			default:
				return 'tl-dot b-primary'
				// break;
		}
	}

	const cetakLaporan = () => {
		if(detailSurat && detailSurat.uuid){
			setLoadingPreview(true)
			setStatePreview(true)
			setIsOpen(false)
			// setlinkPreviewCetak(`${window.location.origin}/api/v1/web/administrasi/pengajuan-surat-mahasiswa/cetak/${detailSurat.uuid}?show=Ya&preview=true`)
			setlinkPreviewCetak(`${process.env.REACT_APP_API_URL}api/v1/web/administrasi/pengajuan-surat-mahasiswa/cetak/${detailSurat.uuid}?show=Ya&preview=true`)

			GlobalServices.cetakLaporan(`administrasi/pengajuan-surat-mahasiswa/cetak/${detailSurat.uuid}?show=Ya&preview=true`, token)
				.then((response) => {
					setLoadingPreview(false);
				})
				.catch((e) => {
					setLoadingPreview(false);
					console.log(e);
				});
		}
		else{
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Cetak Laporan</span>
				</span>,
				'',
			);
		}
	};

	if (id || id === 0) {
		return (
		<>
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size="xl" titleId={id}>
				<ModalHeader setIsOpen={setIsOpen} className="p-4">
					<ModalTitle id={id}>{title} <h4>Detail Pengajuan Surat Mahasiswa</h4><hr /></ModalTitle>
				</ModalHeader>
				<ModalBody className="px-4">
					<div className="row g-4">
						<div className='row'>
							<div className='col-12'>
								<div className='row'>
									<div className='col-4'>
										<div className='col-12' align='center'>
											<h5>Status Disposisi</h5>
										</div>
										{/* <div className='col-12' style={{ overflowX: 'auto'}}> */}
										<div className="col-lg-12" style={{ height: '300px',overflowY: 'scroll'}}>
											<div className="timeline p-4 block mb-4">
												{detailSurat.data_pengajuan_surat_disposisi.map((i, key) => (
													<div className="tl-item">
														<div className={statusColor(i.status)} />
														<div className="tl-content">
															<div className="">
																<b>{i.data_jabatan_struktural.nama} ({i.status})</b>
															</div>
															<div className=""><i>{i.memo}</i></div>
															<div className="tl-date text-muted mt-1">
																{`${new Date(i.created_at).toLocaleDateString('id-ID')} ${new Date(
																	i.created_at
																).toLocaleTimeString('id-ID')}`}
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
									<div className='col-8'>
										<div className='row'>
											<div className='col-12' align='center'>
												<h5>Data Pengajuan</h5>
											</div>
											<br/>
											{statusDisposisi!=='Selesai'?
												<>
													<div className='col-12' align='right'>
													<Button style={{width:'150px'}} color='success' onClick={cetakLaporan}>
														Preview Surat
													</Button>
													</div>
													<br/>
												</>
											:
												<></>
											}

											{dataDetailKeyValueParagraf && dataDetailKeyValueParagraf.map((i, key) => (
												<>
													<div className='col-6'>
														<div><b>{i.data.label}</b></div>
														<div><font size='3'>{i.data.value?i.data.value:'Data Kosong'}</font></div>
													</div>
													<br/><br/><br/>
												</>
											))}

{
												detailSurat.email
												?
												<>
													<div className='col-6'>
														<div><b>Email</b></div>
														<div><font size='3'>{detailSurat.email?detailSurat.email:'Data Kosong'}</font></div>
													</div>
													<br/><br/><br/>
												</>
												: <></>
											}

											{
												detailSurat.nomor_hp
												?
												<>
													<div className='col-6'>
														<div><b>Nomor HP</b></div>
														<div><font size='3'>{detailSurat.nomor_hp?detailSurat.nomor_hp:'Data Kosong'}</font></div>
													</div>
													<br/><br/><br/>
												</>
												: <></>
											}

											{dataDetailParagrafWithValue && dataDetailParagrafWithValue.map((i, key) => (
												<>
													{i.data.map(ii =>
													<> 
														<div className='col-6'>
															<div><b>{ii.label}</b></div>
															<div><font size='3'>{ii.value?ii.value:'Data Kosong'}</font></div>
														</div>
														<br/><br/><br/>
													</>
													)}
												</>
											))}

											{dataDetailVariabel && dataDetailVariabel.map((i, key) => (
												<>
													{i.data.map(ii =>
													<> 
														<div className='col-6'>
															<div><b>{ii.label}</b></div>
															<div><font size='3'>{ii.value?ii.value:'Data Kosong'}</font></div>
														</div>
														<br/><br/><br/>
													</>
													)}
												</>
											))}

											{/* <div className='col-6'>
												<div><b>Nama Pengaju</b></div>
												<div><font size='3'>{detailSurat.nama?detailSurat.nama:'Data Kosong'}</font></div>
											</div>
											<br/><br/><br/>
											<div className='col-6'>
												<div><b>NIM</b></div>
												<div><font size='3'>{detailSurat.nim?detailSurat.nim:'Data Kosong'}</font></div>
											</div>
											<br/><br/><br/>

											{
												detailSurat.prodi
												?
												<>
													<div className='col-6'>
														<div><b>Program Studi</b></div>
														<div><font size='3'>{detailSurat.prodi?detailSurat.prodi:'Data Kosong'}</font></div>
													</div>
													<br/><br/><br/>
												</>
												:
												<>
												</>
											} */}

											<div className='col-6'>
												<div><b>Tanggal Pengajuan</b></div>
												<div><font size='3'>{detailSurat.tanggal_pengajuan?detailSurat.tanggal_pengajuan:'Data Kosong'}</font></div>
											</div>
											<br/><br/><br/>
											
											<div className='col-6'>
												<div><b>Jenis Surat Pengajuan</b></div>
												<div><font size='3'>{detailSurat.data_surat_instansi?detailSurat.data_surat_instansi.nama:'Data Kosong'}</font></div>
											</div>
											<br/><br/><br/>

											{
												detailSurat.nip_dosen_pengampu
												?
												<>
													<div className='col-6'>
														<div><b>NIP Dosen Pengampu</b></div>
														<div><font size='3'>{detailSurat.data_karyawan?detailSurat.data_karyawan.nip:'Data Kosong'}</font></div>
													</div>
													<br/><br/><br/>
													
													<div className='col-6'>
														<div><b>Nama Dosen Pengampu</b></div>
														<div><font size='3'>{detailSurat.data_karyawan?detailSurat.data_karyawan.nama:'Data Kosong'}</font></div>
													</div>
													<br/><br/><br/>
													
												</>
												:
												<>
												</>
											}

											<div className='col-6'>
												<div><b>Kode Pengajuan</b></div>
												<div><font size='3'>{detailSurat.kode_pengajuan?detailSurat.kode_pengajuan:'Data Kosong'}</font></div>
											</div>
											<br/><br/><br/>
											
											{/* {
												detailSurat.nomor_surat
												?
												<>
													<div className='col-6'>
														<div><b>Nomor Surat</b></div>
														<div><font size='3'>{detailSurat.nomor_surat?detailSurat.nomor_surat:'Data Kosong'}</font></div>
													</div>
													<br/><br/><br/>
												</>
												:
												<>
												</>
											} */}

										</div>
									</div>
								</div>
							</div>
						</div>
						
						{/* Dokumen */}

						{
							syaratSurat && syaratSurat.length === 0
							?
								<></>
							:
								<div className='row'>
									<div className='col-12' align='left' style={{ overflowX: 'auto'}}>
										<hr />
									</div>
									<div className='col-12' align='left' style={{ overflowX: 'auto'}}>
										<h5>Dokumen Syarat</h5>
									</div>
									<div className='col-12'>&nbsp;</div>
									{syaratSurat && syaratSurat.map((i, key) => (
										<>
										<div className='col-12' style={{ overflowX: 'auto'}}>
											<a href={i.link} style={{ marginTop: '5px' }} target="_blank" rel="noreferrer">
												<Button
													icon='Eye'
													color='info'
													size='sm'
												>
													Lihat Dokumen
												</Button>
											</a> 
											<font size='3'> - </font>
											<font size='3'>{i.label}</font>
										</div>
										<div className='col-12'>&nbsp;</div>
										</>
									))}
								</div>
						}
					</div>
				</ModalBody>
				<ModalFooter className="px-4 pb-4">
					<Button
						color="primary"
						// isOutline
						// className='border-0'
						onClick={() => {
							setIsOpen(false);
							setstatusTujuanSurat(false)
							setdataDetailKeyValueParagraf([])
							setdataDetailParagrafWithValue([])
							setdataDetailVariabel([])
							// setSurat(initSurat);
							// setSelectedInstansi(null);
						}}
						style={{width:'100px'}}
					>
						Tutup
					</Button>
					{
						roleUser === 'Super Operator'?
						tombolVerif(statusDisposisi)
						: ''
					}
					{ 
						statusSetujuAdmin || statusDisposisi === 'Verifikasi'
						?
						<>
							{roleUser !== 'Super Operator' ? tombolVerif(statusDisposisi) : '' }
							{/* {tombolVerif(statusDisposisi)} */}
						</>
						:
						<>
						</>
					}
				</ModalFooter>
			</Modal>
			
			<Modal
				isOpen={state}
				setIsOpen={setState}
				size='md'
				isStaticBackdrop
				titleId='exampleModalLabel'
				>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader className='p-4'>
					<ModalTitle id={id}>{jenisVerif === 'Selesai'? 'Persetujuan Pengajuan Surat':''} </ModalTitle>
				</ModalHeader>
				{
					loading
					?
					<>
					<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
						<br/><br/><br/><br/>
						<div className='row g-3'>
						{Object.keys(COLORS).map((color) => (
							<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
								<Spinner color={COLORS[color]} isSmall isGrow />
							</div>
						))}
						</div>
						<br/><br/><br/><br/>
					</div>
					</>
				:
					<>
						<ModalBody className='px-4'>
							<div className='row g-4'>
								{
									jenisVerif === 'Selesai'
									?
									<>
										{
											listDisposisiLanjutan.length!==0
											?
											<>
											<FormGroup id='jenis' label='Disposisi Lanjutan' className='col-md-12'>
												<Select
													inputId="disposisi"
													classNamePrefix="select"
													name="disposisi"
													placeholder="Pilih Tujuan Disposisi"
													isClearable
													required
													defaultValue={selectedDisposisi}
													value={selectedDisposisi}
													options={listDisposisiLanjutan}
													onInputChange={e => handleInputSelect(e, "disposisi")}
													onChange={e => {
														handleSelectChange(e, "disposisi");
													}}
												/>
											</FormGroup>
											</>
											:
											<>
											<p align='center'>
												<h3>Yakin Ingin Setujui Pengajuan Surat?</h3> <br />
												Proses ini tidak dapat dibatalkan!
											</p>
											</>
										}
									</>
									:
									<>
										<p align='center'>
											<h3>Yakin Ingin Tolak Pengajuan Surat?</h3> <br />
											Proses ini tidak dapat dibatalkan!
											<br/><br/>
											<FormGroup id='jenis' label='Keterangan' className='col-md-12'>
												<Input
													type='text'
													name='catatan'
													value={dataValues.catatan}
													onChange={handleInputChange}
													required
												/>
											</FormGroup>
										</p>
										<br/>
										<div align='center'>
											<Button
												color='danger'
												// isOutline
												// className='border-0'
												onClick={() => {
													tutupVerifikasi()
												}}
												style={{width:'100px'}}
											>
												Batal
											</Button>
											&nbsp;&nbsp;
											<Button color='success' type='submit' style={{width:'100px'}} onClick={handleSubmit}>
												Ya
											</Button>
										</div>
									</>
								}
							</div>
						</ModalBody>
						<ModalFooter className='px-4 pb-4'>
							{
								jenisVerif === 'Selesai'
								?
								<>
									<Button
										color='danger'
										// isOutline
										// className='border-0'
										onClick={() => {
											tutupVerifikasi()
										}}
										style={{width:'100px'}}
									>
										Batal
									</Button>
									{/* <Button color='info' onClick={formik.handleSubmit}> */}
									<Button color='success' type='submit' style={{width:'100px'}} onClick={handleSubmit}>
										Simpan
									</Button>
								</>
								:
								<>
								</>
							}
						</ModalFooter>
					</>
				}
				</form>
			</Modal>

			<Modal
				isOpen={stateVerif}
				setIsOpen={setStateVerif}
				size='md'
				isStaticBackdrop
				titleId='exampleModalLabel'
				>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader className='p-4'>
					<ModalTitle id={id}>{statusDisposisi === 'Persetujuan' ? 'Persetujuan Pengajuan Surat' : 'Verifikasi'}</ModalTitle>
				</ModalHeader>
				{
					loading
					?
					<>
					<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
						<br/><br/><br/><br/>
						<div className='row g-3'>
						{Object.keys(COLORS).map((color) => (
							<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
								<Spinner color={COLORS[color]} isSmall isGrow />
							</div>
						))}
						</div>
						<br/><br/><br/><br/>
					</div>
					</>
				:
					<>
					<ModalBody className='px-4'>
					{
						statusDisposisi === 'Persetujuan' 
						?
						<div className='row g-4'>
							<FormGroup id='catatan' label='Catatan' className='col-md-12'>
								<Input
									type='text'
									name='catatan'
									value={dataValues.catatan}
									onChange={handleInputChange}
									required
								/>
							</FormGroup>
						</div>
						:
							<div className='row g-4'>
								<FormGroup id='catatan' label='Tanda Tangan *' className='col-md-12'>
									<Select
										inputId="nip_ttd"
										classNamePrefix="select"
										name="nip_ttd"
										placeholder="Pilih Penandatangan"
										isClearable
										required
										defaultValue={selectedTTD}
										value={selectedTTD}
										options={listTTD}
										onInputChange={e => handleInputSelect(e, "ttd")}
										onChange={e => {
											handleSelectChange(e, "ttd");
										}}
									/>
								</FormGroup>
								{
									loadingTTD
									?
									<>
									<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
										<div className='row g-3'>
										{Object.keys(COLORS).map((color) => (
											<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
												<Spinner color={COLORS[color]} isSmall isGrow />
											</div>
										))}
										</div>
									</div>
									</>
								:
									<>
									{
										selectedTTD
										?
										<>
											<FormGroup id='nomor_surat' label='Nomor Surat *' className='col-md-12'>
												<Select
													inputId="nomor_surat_next"
													classNamePrefix="select"
													name="nomor_surat_next"
													placeholder="Pilih Nomor Surat Yg Disarankan"
													isClearable
													required
													defaultValue={selectedNomorSuratNext}
													value={selectedNomorSuratNext}
													options={nomorSuratNext}
													onInputChange={e => handleInputSelect(e, "nomor_surat_next")}
													onChange={e => {
														handleSelectChange(e, "nomor_surat_next");
													}}
												/>
											</FormGroup>
											{
												statusOpenButton?
												<>
												<div className='col-12'>
													<Button
														color={statusInput?'danger':'primary'}
														// isOutline
														className='btn btn-sm'
														style={{width:'250px'}}
														onClick={() => {
															setStatusInput(!statusInput);
															
															let nomorNext = selectedNomorSuratNext.value;

															let splitNomor = nomorNext.split('/')

															let splitNomorEdit = splitNomor[0].split('.');

															let setNomorEdit = splitNomorEdit[1];

															setdataValues({...dataValues, nomor_surat: setNomorEdit})
														}}>
														Input Nomor Surat {statusInput ? "Otomatis" : "Manual"}
													</Button>
												</div>
												</>
												:
												<>
												</>
											}
											
											{statusInput?
												<>
												<FormGroup id='tujuan' label='Nomor Surat Manual*' className='col-md-12'>
													<div className='col-12'>
														<div className='row'>
															<div className='col-2'>
																<Input
																	type='text'
																	name='nomor_surat2'
																	value={formatNomorSurat(selectedNomorSuratNext.value, 'awal')}
																	readOnly
																/>
															</div>
															<div className='col-2'>
																<Input
																	type='number'
																	name='nomor_surat'
																	value={dataValues.nomor_surat}
																	onChange={handleInputChange}
																	required
																/>
															</div>
															<div className='col-8'>
																<Input
																	type='text'
																	name='nomor_surat2'
																	value={formatNomorSurat(selectedNomorSuratNext.value, 'akhir')}
																	readOnly
																/>
															</div>
														</div>
													</div>
												</FormGroup>
												</>
												:
												<>
												</>
											}
										</>
										:
										<>
										</>
									}
									</>
								}

								{
									statusTujuanSurat
									?
									<>
									<div className='col-12' align='center'>
										<b>Tujuan Surat</b>
										<hr/>
									</div>
									<FormGroup id='tujuan' label='Kepada Yth. *' className='col-md-12'>
										<div className='col-12'>
											<div className='row'>
												<div className='col-4'>
													<Select
														inputId="jabatan_pengganti_tujuan"
														classNamePrefix="select"
														name="jabatan_pengganti_tujuan"
														placeholder="Jabatan"
														isClearable
														required
														defaultValue={selectedStatusPengganti}
														value={selectedStatusPengganti}
														options={listStatusPenggantiTTD}
														onInputChange={e => handleInputSelect(e, "jabatan_pengganti_tujuan")}
														onChange={e => {
															handleSelectChange(e, "jabatan_pengganti_tujuan");
														}}
													/>
												</div>
												<div className='col-8'>
													<Input
														type='text'
														name='tujuan'
														value={dataValues.tujuan}
														onChange={handleInputChange}
														required
													/>
												</div>
											</div>
										</div>
									</FormGroup>
									{/* <FormGroup id='tujuan' label='Kepada Yth. *' className='col-md-12'>
										<Input
											type='text'
											name='tujuan'
											value={dataValues.tujuan}
											onChange={handleInputChange}
											required
										/>
									</FormGroup> */}
									<FormGroup id='tempat' label='Tempat *' className='col-md-12'>
										<Input
											type='text'
											name='tempat'
											value={dataValues.tempat}
											onChange={handleInputChange}
											required
										/>
									</FormGroup>
									<div className='col-12'>
										<Button
											color={statusCQ?'danger':'primary'}
											// isOutline
											// className='border-0'
											style={{width:'130px'}}
											onClick={() => {
												setstatusCQ(!statusCQ);
												setdataValues({...dataValues, cq:''});
											}}>
											{statusCQ?'Hapus':'Tambah'} c.q
										</Button>
									</div>
									{
										statusCQ
										?
										<>
											<FormGroup id='cq' label='c.q' className='col-md-12'>
												<Input
													type='text'
													name='cq'
													value={dataValues.cq}
													onChange={handleInputChange}
												/>
											</FormGroup>
										</>
										:
										<></>
									}
									</>
								:
									<></>
								}
								<div className='col-12' align='center'>
									<b>Catatan</b>
									<hr/>
								</div>
								<FormGroup id='catatan' label='' className='col-md-12'>
									<Input
										type='text'
										name='catatan'
										value={dataValues.catatan}
										onChange={handleInputChange}
										style={{height:'50px'}}
									/>
								</FormGroup>
							</div>
						}
					</ModalBody>
					<ModalFooter className='px-4 pb-4'>
						<Button
							color='danger'
							style={{width:'100px'}}
							// isOutline
							// className='border-0'
							onClick={() => {
								tutupVerifikasi()
								setdataValues(formData);
							}}
						>
							Batal
						</Button>
						{/* <Button color='info' onClick={formik.handleSubmit}> */}
						<Button color='success' style={{width:'100px'}} type='submit' onClick={handleSubmitVerifSurat}>
							Simpan
						</Button>
					</ModalFooter>
					</>
				}
				</form>
			</Modal>

			<Modal
				isOpen={statePreview}
				setIsOpen={setStatePreview}
				size='xl'
				isStaticBackdrop
				titleId='exampleModalLabel'
				>
				<ModalHeader className='p-4'>
					<ModalTitle id={id}>Preview Surat</ModalTitle>
				</ModalHeader>
				{
					loadingPreview
					?
					<>
					<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
						<br/><br/><br/><br/>
						
						<div className='row g-3'>
						{Object.keys(COLORS).map((color) => (
							<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
								<Spinner color={COLORS[color]} isSmall isGrow />
							</div>
						))}
						</div>
						<br/><br/><br/><br/>
					</div>
					</>
				:
					<>
						<ModalBody className='px-4'>
							<Iframe 
								id="myFrame" 
								src={linkPreviewCetak}
								width="100%"
								height="450px"
								className="myClassname"
								display="initial"
								position="relative"
								controls="0"
							/>
						</ModalBody>
						<ModalFooter className='px-4 pb-4'>
							<Button
								color='danger'
								style={{width:'100px'}}
								onClick={() => {
									setStatePreview(false);
									setlinkPreviewCetak('');
									setIsOpen(true);
								}}
							>
								Tutup
							</Button>
						</ModalFooter>
					</>
				}
			</Modal>
		</>	
		);
	}
	return null;
};
ModalDetail.propTypes = {
	id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	syaratSurat: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
	statusDisposisi: PropTypes.func.isRequired,
	// listTujuanSurat: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ModalDetail;
