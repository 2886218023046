import http from './http-common';

const getAll = () => {
	return http
		.get('/absensi/riwayat')
		.then((response) => response.data)
		.catch((error) => {
			throw error;
		});
};

const getBy = (idInstansi, start, end) => {
	let dateEnd = '';
	if (end) {
		dateEnd = `&end=${end}`;
	}
	return http.get(`/absensi/riwayat/${idInstansi}?start=${start}${dateEnd}`);
};

export default {
	getAll,
	getBy,
};
