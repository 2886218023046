import React, { useState, useEffect, useRef } from 'react';
import Select from "react-select";
import { Link, useLocation, useHistory } from 'react-router-dom';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import SelectData from './Services/SelectData';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import ModalDetail from './SuratKeluar/ModalDetail';

// Alert
import showNotification from '../extras/showNotification';
import GlobalServices from './Services/GlobalServices';

const SuratKeluarStatus = ({ setReset, statusTab }) => {
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansiRole = localStorage.getItem('uuid_instansi');
	const token = sessionStorage.getItem('access_token');
	const jabatanStrukturalUser = localStorage.getItem('uuid_jabatan_struktural');

	const location = useLocation()
  	const setDataProp = location.state?.dataProp

	// Fitur Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	// End Fitur Pagination

	// Fetching Data
	const [loading, setLoading] = useState(true);
	const [list, setList] = useState([]);
	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});

	const getDataList = (page) => {
		let extra = ''

		let sortData = '';
		if(selectedSorting && selectedSorting.value === 'Terlama'){
			sortData = '&order_type=asc';
		}
		else{
			sortData = '&order_type=desc';
		}

		let firstDate = '';
		let lastDate = '';
		if(paramValue.startDate){
			firstDate = `&first_date=${paramValue.startDate}`;
		}
		if(paramValue.endDate){
			lastDate = `&last_date=${paramValue.endDate}`;
		}

		if (page) {
			extra = `&page=${page}&order_by=created_at${sortData}${firstDate}${lastDate}`
		}

		let link = ''
		if(roleUser === 'Admin'){
			link=`administrasi/pengajuan-surat/jabatan/${jabatanStrukturalUser}?status=Proses${extra}&q=${cariValue.nama}`;
		}
		else{
			link=`administrasi/pengajuan-surat/jabatan/${jabatanStrukturalUser}?status=Proses${extra}&q=${cariValue.nama}`;
		}

		// GlobalServices.getAll(`administrasi/pengajuan-surat/jabatan/${jabatanStrukturalUser}?status=Proses${extra}`)

		GlobalServices.getAll(link, token)
			.then((response) => {
				console.log('Hasil PErsetujuan')
				console.log(response)
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
				setLoading(false)
				
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	};

	const refreshList = (page = '') => {
		setLoading(true)
		setTimeout(() => {
			getDataList(page);
			setLoading(false)
		}, 2000);
	};

	const firstUpdate = useRef(true);

	useEffect(() => {
		// let mounted = true;

		// if (firstUpdate.current) {
		// 	firstUpdate.current = false;
		// 	if(mounted && statusTab === 1) {
		// 		getDataList()
		// 	}
		// }

		// const intervalId = setInterval(() => {
		// 	if(mounted && statusTab === 1) {
		// 		getDataList()
		// 	}
		// }, 3000)

		// return () => {
		// 	clearInterval(intervalId);	
		// 	mounted = false;
		// }
		getDataList()
	}, [setReset]); // eslint-disable-line react-hooks/exhaustive-deps

	// End Fetching Data

	// Fitur Modal
	const [detailModalStatus, setDetailModalStatus] = useState(false);

	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};
	// End Fitur Modal

	// Fitur Detail Data
	const [detailSurat, setDetailSurat] = useState({
		data_karyawan: {},
		data_pengajuan_surat_disposisi: [],
		data_surat_instansi: {},
		status: '',
		syarat_surat: '',
		tanggal_pengajuan: '',
		uuid: '',
		uuid_surat_instansi: '',
		uuid_karyawan: '',
	})
	const [syaratSurat, setSyaratSurat] = useState([]);

	const detailData = (data) => {
		if (data) {
			console.log(data);
			setDetailSurat(data);
			setSyaratSurat(JSON.parse(data.syarat_surat));
		}
	};
	// End Fitur Detail Data

	// Fitur Pencarian
	const dataCari = {
		nama: '',
	};

	const [cariValue, setcariValue] = useState(dataCari);
	const handleInputSearch = (event) => {
		const { name, value } = event.target;
		setcariValue({ ...cariValue, [name]: value });
	};

	const handleSearch = () => {
		
		let sortData = '';
		if(selectedSorting && selectedSorting.value === 'Terlama'){
			sortData = '&order_type=asc';
		}
		else{
			sortData = '&order_type=desc';
		}

		let firstDate = '';
		let lastDate = '';
		if(paramValue.startDate){
			firstDate = `&first_date=${paramValue.startDate}`;
		}
		if(paramValue.endDate){
			lastDate = `&last_date=${paramValue.endDate}`;
		}

		let extra = `&page=${1}&order_by=created_at${sortData}${firstDate}${lastDate}`

		let link = ''
		if(roleUser === 'Admin'){
			link=`administrasi/pengajuan-surat/jabatan/${jabatanStrukturalUser}?status=Proses${extra}`;
		}
		else{
			link=`administrasi/pengajuan-surat/jabatan/${jabatanStrukturalUser}?status=Proses${extra}`;
		}
	
		GlobalServices.getSearch(link, cariValue.nama, token)
			.then((response) => {
				setList(response.data.results.data);
				setPagination(response.data.results)
				setCurrentPage(response.data.results.current_page)
				setPerPage(response.data.results.per_page)
				
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const handleSearchEnter = (event) => {
		if (event.key === 'Enter') {
			let sortData = '';
			if(selectedSorting && selectedSorting.value === 'Terlama'){
				sortData = '&order_type=asc';
			}
			else{
				sortData = '&order_type=desc';
			}

			let firstDate = '';
			let lastDate = '';
			if(paramValue.startDate){
				firstDate = `&first_date=${paramValue.startDate}`;
			}
			if(paramValue.endDate){
				lastDate = `&last_date=${paramValue.endDate}`;
			}

			let extra = `&page=${1}&order_by=created_at${sortData}${firstDate}${lastDate}`

			let link = ''
			if(roleUser === 'Admin'){
				link=`administrasi/pengajuan-surat/jabatan/${jabatanStrukturalUser}?status=Proses${extra}`;
			}
			else{
				link=`administrasi/pengajuan-surat/jabatan/${jabatanStrukturalUser}?status=Proses${extra}`;
			}

			GlobalServices.getSearch(link, cariValue.nama, token)
				.then((response) => {
					console.log(response);
					setList(response.data.results.data);
					setPagination(response.data.results)
					setCurrentPage(response.data.results.current_page)
					setPerPage(response.data.results.per_page)
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};
	// End Fitur Pencarian
	
	// Fitur Sorting
	const [stateFilter, setStateFilter] = useState(false);
	const [listSorting, setListSorting] = useState(SelectData.sortData);
	const [selectedSorting, setSelectedSorting] = useState(null);

	const handleInputSorting = (e, jenis) => {
		if (e) {
			if (jenis === "sorting") {
				let newArray = listSorting;	
				newArray = newArray.filter(day => day !== e);
				setListSorting(newArray)
			}
		}
	};

	const handleSelectSorting = (event, param) => {
		if (event) {
			switch (param) {
				case 'sorting':
					setSelectedSorting(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'sorting':
					setSelectedSorting(null);
					break;
				default:
					// no default
					break;
			}
		}
	};
	// End Fitur Sorting

	// Fitur Filter Date
	const paramState = {
		startDate: '',
		endDate: '',
	};
	const [paramValue, setParam] = useState(paramState);
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setParam({ ...paramValue, [name]: value });
	};
	// End Fitur Filter Date

	// Proses Filter Data
	const filterData = () => {
		// console.log('Data Filter Berhasil')
		// console.log(paramValue.startDate)
		// console.log(paramValue.endDate)
		// console.log(selectedSorting)
		let sortData = '';
		if(selectedSorting && selectedSorting.value === 'Terlama'){
			sortData = '&order_type=asc';
		}
		else{
			sortData = '&order_type=desc';
		}
		let extra = `&page=${1}&order_by=created_at${sortData}&first_date=${paramValue.startDate}&last_date=${paramValue.endDate}`

		let link = ''
		if(roleUser === 'Admin'){
			link=`administrasi/pengajuan-surat/jabatan/${jabatanStrukturalUser}?status=Proses${extra}&q=${cariValue.nama}`;
		}
		else{
			link=`administrasi/pengajuan-surat/jabatan/${jabatanStrukturalUser}?status=Proses${extra}&q=${cariValue.nama}`;
		}

		GlobalServices.getAll(link, token)
			.then((response) => {
				// console.log('Data Surat Masuk Instansi Filter')
				// console.log(response)
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
				tutupFormFilter()
			})
			.catch((e) => {
				console.log(e);
			});
	};
	// End Proses Filter Data

	const tutupFormFilter = () => {
		setStateFilter(false);
		// setParam(paramState);
		// setSelectedSorting(null);
	};

	return (
		<>
			{
				loading
				?
				<>
				<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
					<br/><br/><br/><br/>
					<div className='row g-3'>
					{Object.keys(COLORS).map((color) => (
						<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
							<Spinner color={COLORS[color]} isSmall isGrow />
						</div>
					))}
					</div>
					<br/><br/><br/><br/>
				</div>
				</>
			:
				<>
					<div className='row'>
						<div className='col-6' align='left'>
							&nbsp;
						</div>
						<div className='col-6'>
							<div className='row'>
								<div className='col-10'>
									<Input
										type='text'
										name='nama'
										value={cariValue.nama}
										onChange={handleInputSearch}
										isRequired
										onKeyDown={handleSearchEnter}
									/>
								</div>
								<div className='col-1' align='right'>
									<Button
										icon='Search'
										color='primary'
										// isLight
										onClick={handleSearch}>
										{/* Cari Data */}
									</Button>
								</div>
								<div className='col-1' align='right'>
									<Button
										icon='FilterAlt'
										color='dark'
										isLight
										className='btn-only-icon position-relative'
										onClick={() => setStateFilter(true)}
									>
											&nbsp;
									</Button>
								</div>
							</div>
						</div>
					</div>
					<br />
					<table className='table table-modern table-hover'>
						<thead>
							<tr>
								{/* <th>No.</th> */}
								<th>Tanggal Pengajuan</th>
								<th>Nama Pengaju</th>
								<th>NIP</th>
								<th>Jenis Surat</th>
								<th style={{ width: '150px', textAlign: 'center' }}>Aksi</th>
							</tr>
						</thead>
						<tbody>
							{list.map(
								(i, key) => (
									<tr key={i.id}>
										{/* <td>{key + 1}</td> */}
										<td>{i.tanggal_pengajuan}</td>
										<td>{i.data_karyawan.nama}</td>
										<td>{i.data_karyawan.nip}</td>
										<td>{i.data_surat_instansi.nama}</td>
										<td style={{ textAlign: 'center' }}>
											<Button
												icon='Eye'
												color='info'
												title='Lihat Detail Disposisi'
												onClick={() => {
													detailData(i);
													setDetailModalStatus(true)
												}} 
											/>
											{/* {'  '}
													<a href={`${process.env.REACT_APP_API_URL}api/v1/web/administrasi/pengajuan-surat/karyawan/cetak/${i.uuid}`} alt=''>
													<Button
														icon='Print'
														color='success'
														title='Cetak Surat'
													/>
													</a> */}
										</td>
									</tr>
								),
							)}
						</tbody>
					</table>

					<PaginationButtons
						data={list}
						label='Surat Dosen Status Menunggu Persetujuan'
						setCurrentPage={getDataList}
						setPerPage={setPerPage}
						currentPage={currentPage}
						perPage={perPage}
						paginationDetail={pagination}
						setpaginationDetail={setPagination}
					/>
				</>
			}
				
			<ModalDetail
				setIsOpen={setDetailModalStatus}
				isOpen={detailModalStatus}
				id={0}
				uuidInstansi={uuidInstansiRole}
				detailSurat={detailSurat}
				syaratSurat={syaratSurat}
				statusRefresh={refreshList}
				statusDisposisi='Persetujuan'
			/>
			
			<Modal
				isOpen={stateFilter}
				setIsOpen={setStateFilter}
				titleId='exampleModalLabel'
				isCentered={centeredStatus}>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<div className='row'>
						<div className='col-6' align='center'>
							<p align='left'><b>Dari Tanggal</b></p>
							<Input
								type='date'
								name='startDate'
								value={paramValue.startDate}
								onChange={handleInputChange}
								// isRequired
							/>
						</div>
						<div className='col-6' align='right'>
							<p align='left'><b>Sampai Tanggal</b></p>
							<Input
								type='date'
								name='endDate'
								value={paramValue.endDate}
								onChange={handleInputChange}
								// isRequired
							/>
						</div>
						<div className='col-12'>&nbsp;</div>
						<div className='col-6' align='right'>
							<p align='left'><b>Urut Dari</b>
							<Select
								inputId="sorting"
								classNamePrefix="select"
								name="sorting"
								placeholder="Pilih Urutan"
								isClearable
								required
								defaultValue={selectedSorting}
								value={selectedSorting}
								options={listSorting}
								onInputChange={e => handleInputSorting(e, "sorting")}
								onChange={e => {
									handleSelectSorting(e, "sorting");
								}}
							/>
							</p>
						</div>
					</div>
					<br />
					<div align='right'>
						<Button
							color='info'
							onClick={tutupFormFilter}>
							Tutup
						</Button>
						{'  '}
						<Button color='success' onClick={filterData}>
							Lihat Data
						</Button>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default SuratKeluarStatus;
